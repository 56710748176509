<template>
  <asterix-application-layout>
    <template #sidebar>
      <asterix-sidebar v-model="isSidebarOpen">
        <sun-sidebar-group class="mt-8">
          <sun-sidebar-nav-item :to="analytics">
            <template #icon><analytics-svg class="w-4" /></template>Analytics
            <template #children>
              <sun-sidebar-nav-item :to="stats"> Stats </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="clickId"> Check Click ID </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="logs"> Logs </sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>

          <sun-sidebar-nav-item :to="admin">
            <template #icon><admin-svg class="w-4" /></template>
            Admin
            <template #children>
              <sun-sidebar-nav-item :to="users"> Users </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="blockList"> Blocked List </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="countryList"> Countries </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="fraudTemplates"> Fraud Templates </sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>
          <sun-sidebar-nav-item :to="supply">
            <template #icon><supply-svg class="w-4" /></template>
            Supply
            <template #children>
              <sun-sidebar-nav-item :to="publisher"> Publisher </sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>
          <sun-sidebar-nav-item :to="demand">
            <template #icon><demand-svg class="w-4" /></template>
            Demand
            <template #children>
              <sun-sidebar-nav-item :to="advertiser"> Advertisers </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="campaign"> Campaigns </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="feedParserProvider"> Feed Parser</sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="offer"> Offer </sun-sidebar-nav-item>
              <sun-sidebar-nav-item :to="postEvents"> Post Events </sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>
          <sun-sidebar-nav-item :to="billing">
            <template #icon><supply-svg class="w-4" /></template>
            Billing
            <template #children>
              <sun-sidebar-nav-item :to="publisherTechFee"> Tech Fee </sun-sidebar-nav-item>
            </template>
          </sun-sidebar-nav-item>
        </sun-sidebar-group>
      </asterix-sidebar>
    </template>

    <template #header>
      <asterix-topbar class="z-20" @change="isSidebarOpen = !isSidebarOpen" />
    </template>
  </asterix-application-layout>
</template>

<script>
import AsterixApplicationLayout from '@/components/templates/AsterixApplicationLayout';
import AsterixSidebar from '@/components/molecules/shared/AsterixSidebar/AsterixSidebar';
import AsterixTopbar from '@/components/molecules/shared/AsterixTopbar';
import SunSidebarNavItem from '@/components/molecules/SunSidebarNavItem';
import AdminSvg from '@/components/icons/AdminSvg';
import DemandSvg from '@/components/icons/DemandSvg';
import SupplySvg from '@/components/icons/SupplySvg';
import AnalyticsSvg from '@/components/icons/AnalyticsSvg';
import { demand } from '@/router/private/modules/ecommerce/demand/index.js';
import { supply } from '@/router/private/modules/ecommerce/supply/index.js';
import { publisher } from '@/router/private/modules/ecommerce/supply/publisher';
import { admin } from '@/router/private/modules/ecommerce/admin/index.js';
import { advertiser } from '@/router/private/modules/ecommerce/demand/advertiser';
import { campaign } from '@/router/private/modules/ecommerce/demand/campaign';
import { users } from '@/router/private/modules/ecommerce/admin/users';
import { postEvents } from '@/router/private/modules/ecommerce/demand/postEventTemplates';
import { offer } from '@/router/private/modules/ecommerce/demand/offer';
import { analytics } from '@/router/private/modules/ecommerce/analytics/index.js';
import { stats } from '@/router/private/modules/ecommerce/analytics/stats';
import { clickId } from '@/router/private/modules/ecommerce/analytics/clickId';
import { blockList } from '@/router/private/modules/ecommerce/admin/blocks';
import { countryList } from '@/router/private/modules/ecommerce/admin/countries';
import { fraudTemplates } from '@/router/private/modules/ecommerce/admin/fraudTemplates';
import { feedParserProvider } from '@/router/private/modules/ecommerce/demand/feedParserProvider';
import { billing } from '@/router/private/modules/ecommerce/billing';
import { publisherTechFee } from '@/router/private/modules/ecommerce/billing/publisherTechFee';
import { logs } from '@/router/private/modules/ecommerce/analytics/logs';

export default {
  name: 'EcommerceIndex',
  components: {
    AsterixApplicationLayout,
    AsterixSidebar,
    AsterixTopbar,
    SunSidebarNavItem,
    DemandSvg,
    SupplySvg,
    AdminSvg,
    AnalyticsSvg,
  },
  data() {
    return {
      isSidebarOpen: true,
      demand,
      supply,
      admin,
      users,
      advertiser,
      campaign,
      offer,
      publisher,
      stats,
      blockList,
      countryList,
      fraudTemplates,
      feedParserProvider,
      postEvents,
      publisherTechFee,
      billing,
      clickId,
      analytics,
      logs,
    };
  },
};
</script>
