var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "1 0 16 16" } },
    [
      _c("defs"),
      _c("title", [_vm._v("Demand")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M3.83,9.61a1.1,1.1,0,0,0,.25.23s.08.06.08.11a1.27,1.27,0,0,0,.35.78.13.13,\n        0,0,1,0,.1c0,.14,0,.28,0,.43a2.72,2.72,0,0,0,1,.22,3,3,0,0,0,1.06-.24.31.31,\n        0,0,1,0-.1V11c0-.32,0-.2.13-.34A1.49,1.49,0,0,0,6.94,10,.19.19,0,0,1,7,\n        9.83a.63.63,0,0,0,.18-.91c-.07-.08,0-.08,0-.23a1.92,1.92,0,0,0,0-1.23A1.38,\n        1.38,0,0,0,6,6.38a1.69,1.69,0,0,0-1.17.21.89.89,0,0,1-.31.15,1,1,0,0,0-.76.92,\n        2.65,2.65,0,0,0,.15,1.08.17.17,0,0,1,0,.17A.59.59,0,0,0,3.83,9.61Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M15.53,2.5H9a1.06,1.06,0,0,0-1.06,1V7.94A1.07,1.07,0,0,0,9,9h1.16L10,\n        10l-.32,0a13.53,13.53,0,0,1-.28,1.73c-.12.35-.8.26-.8.26l-1.95-.26h0a2.48,\n        2.48,0,0,1-1.09.3,2.49,2.49,0,0,1-1.1-.3,4.55,4.55,0,0,1-1,.44C2,12.62,\n        2,15,1.93,15.58c0,.22.15.34.37.35H8.42c.22,0,.39-.13.38-.35a16,16,0,0,\n        0-.07-1.91l1,.05c1,0,1.12-.65,1.12-.65l.55-3-.31,0L11.24,9h4.29a1.06,\n        1.06,0,0,0,1-1.06V3.55A1,1,0,0,0,15.53,2.5Zm.35,5.44a.36.36,0,0,\n        1-.35.36H9a.36.36,0,0,1-.36-.36V3.55A.36.36,0,0,1,9,3.2h6.55a.35.35,0,0,1,.35.35Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M11.89,6.45a.66.66,0,0,0,.24.34.83.83,0,0,0,.45.11,2,2,0,0,0,.35,0,2.25,\n        2.25,0,0,0,.35-.09,3.44,3.44,0,0,1,.14.37c0,.12.06.25.08.38a2.49,2.49,0,0,\n        1-.54.15,3.68,3.68,0,0,1-.49,0,2,2,0,0,1-.62-.08,1.33,1.33,0,0,1-.45-.26,\n        1.26,1.26,0,0,1-.32-.4,1.92,1.92,0,0,1-.18-.51h-.46a1,1,0,0,1,\n        0-.48h.4V5.59h-.4a.67.67,0,0,1,0-.23.78.78,0,0,1,0-.24h.46a1.82,\n        1.82,0,0,1,.19-.54,1.43,1.43,0,0,1,.31-.42,1.24,1.24,0,0,1,.46-.27,\n        1.57,1.57,0,0,1,.6-.1h.25l.23,0a1.05,1.05,0,0,1,.24.06l.26.09a1.41,\n        1.41,0,0,1-.07.41,1.59,1.59,0,0,1-.14.34,2.63,2.63,0,0,0-.37-.1,1.58,\n        1.58,0,0,0-.32,0,.63.63,0,0,0-.43.13.83.83,0,0,0-.23.39h1a.78.78,0,0,\n        1,0,.24.67.67,0,0,1,0,.23h-1V6h1a1,1,0,0,1,0,.48Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }