var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-application-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function () {
          return [
            _c(
              "asterix-sidebar",
              {
                model: {
                  value: _vm.isSidebarOpen,
                  callback: function ($$v) {
                    _vm.isSidebarOpen = $$v
                  },
                  expression: "isSidebarOpen",
                },
              },
              [
                _c(
                  "sun-sidebar-group",
                  { staticClass: "mt-8" },
                  [
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.analytics },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("analytics-svg", { staticClass: "w-4" }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "children",
                            fn: function () {
                              return [
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.stats } },
                                  [_vm._v(" Stats ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.clickId } },
                                  [_vm._v(" Check Click ID ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.logs } },
                                  [_vm._v(" Logs ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_vm._v("Analytics ")]
                    ),
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.admin },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c("admin-svg", { staticClass: "w-4" })]
                            },
                            proxy: true,
                          },
                          {
                            key: "children",
                            fn: function () {
                              return [
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.users } },
                                  [_vm._v(" Users ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.blockList } },
                                  [_vm._v(" Blocked List ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.countryList } },
                                  [_vm._v(" Countries ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.fraudTemplates } },
                                  [_vm._v(" Fraud Templates ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_vm._v(" Admin ")]
                    ),
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.supply },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c("supply-svg", { staticClass: "w-4" })]
                            },
                            proxy: true,
                          },
                          {
                            key: "children",
                            fn: function () {
                              return [
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.publisher } },
                                  [_vm._v(" Publisher ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_vm._v(" Supply ")]
                    ),
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.demand },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c("demand-svg", { staticClass: "w-4" })]
                            },
                            proxy: true,
                          },
                          {
                            key: "children",
                            fn: function () {
                              return [
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.advertiser } },
                                  [_vm._v(" Advertisers ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.campaign } },
                                  [_vm._v(" Campaigns ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.feedParserProvider } },
                                  [_vm._v(" Feed Parser")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.offer } },
                                  [_vm._v(" Offer ")]
                                ),
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.postEvents } },
                                  [_vm._v(" Post Events ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_vm._v(" Demand ")]
                    ),
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.billing },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [_c("supply-svg", { staticClass: "w-4" })]
                            },
                            proxy: true,
                          },
                          {
                            key: "children",
                            fn: function () {
                              return [
                                _c(
                                  "sun-sidebar-nav-item",
                                  { attrs: { to: _vm.publisherTechFee } },
                                  [_vm._v(" Tech Fee ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_vm._v(" Billing ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "header",
        fn: function () {
          return [
            _c("asterix-topbar", {
              staticClass: "z-20",
              on: {
                change: function ($event) {
                  _vm.isSidebarOpen = !_vm.isSidebarOpen
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }