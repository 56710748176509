var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 18" } },
    [
      _c("title", [_vm._v("Suply")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M7.37,8.39l-.23.82H6.39L7.37,6h1l1,\n        3.22H8.54l-.25-.82Zm.82-.55L8,7.16c-.06-.19-.12-.43-.16-.62h0c-.05.19-.1.43-.15.62l-.19.68Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M11.93,5.82V8.51c0,.27,0,.54,0,.7H11.3l0-.34h0a.82.82,0,0,1-.73.39,\n        1.07,1.07,0,0,1-1-1.2,1.11,1.11,0,0,1,1.05-1.24.71.71,0,0,1,.61.26h0V5.82Zm-.73,\n        2a.62.62,0,0,0,0-.13.43.43,0,0,0-.42-.35c-.33,0-.5.29-.5.66s.19.65.49.65a.45.45,\n        0,0,0,.43-.35,1,1,0,0,0,0-.17Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M16.44,2.81l-1.63-.9a.74.74,0,0,0-.36-.09H6.51a.5.5,0,0,0-.5.51V2.9H5.52a.5.5,\n        0,0,0-.5.51v.65h-.5a.5.5,0,0,0-.5.51v5.8a2.42,2.42,0,0,0-.75.38,11.3,11.3,0,0,\n        1-2,1.22l-.11,0v3.91a.6.6,0,0,0,.31-.1c.33-.2.64-.45,1-.63a6.68,6.68,0,0,1,\n        3.33-.59c1.2,0,2.4.11,3.6.19a1.69,1.69,0,0,0,1-.26,43.19,43.19,0,0,1,5.34-2.82,\n        2,2,0,0,0,.32-.17.57.57,0,0,0,0-1A1.36,1.36,0,0,0,15,10.38l-.17,0a1,1,0,0,0,\n        0-.17V10l.58-.27a.73.73,0,0,0,.42-.67V9l.56-.27A.74.74,0,0,0,16.83,8V3.46A.74.74,\n        0,0,0,16.44,2.81Zm-5,8.54c.15,1.13-.22,1.65-1.36,1.78a14.55,14.55,0,0,1-3.6-.28A1.51,\n        1.51,0,0,1,7,12.7c.9,0,1.8,0,2.69,0a3.38,3.38,0,0,0,.78-.07.77.77,0,0,0,.56-.75.7.7,\n        0,0,0-.54-.76A4.14,4.14,0,0,0,9.59,11a20.19,20.19,0,0,1-4.17-.62,2.23,2.23,0,0,\n        0-.8-.08V4.67h7.92l1.63.9a.17.17,0,0,1,.07.13v4.55a.13.13,0,0,1-.08.13l-1.05.5ZM15.24,\n        9.1a.16.16,0,0,1-.08.13l-.32.15V5.71a.75.75,0,0,0-.38-.65l-1.63-.9a.78.78,0,0,\n        0-.36-.1H5.62V3.5h7.85l.07,0,1.63.9a.16.16,0,0,1,.07.13Zm1-1.08a.16.16,0,0,\n        1-.08.13l-.31.14V4.55a.76.76,0,0,0-.38-.66L13.83,3a.78.78,0,0,\n        0-.36-.1H6.61V2.42h7.84l.07,0,1.63.9a.13.13,0,0,1,.08.12Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }