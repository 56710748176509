var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "1 1 15 15" } },
    [
      _c("title", [_vm._v("Admin")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M6.82,9.15a1.36,1.36,0,0,0,.33.3.21.21,0,0,1,.1.14,1.51,1.51,\n        0,0,0,.44,1,.19.19,0,0,1,0,.13v.53A3.15,3.15,0,0,0,9,11.52a3.74,\n        3.74,0,0,0,1.33-.3v-.14a.86.86,0,0,1,0-.16c0-.41,0-.26.16-.44a1.87,\n        1.87,0,0,0,.3-.82c0-.12,0-.14.11-.22a.8.8,0,0,0,.23-1.15c-.08-.1,\n        0-.1.05-.29a2.38,2.38,0,0,0,0-1.56A1.72,1.72,0,0,0,9.58,5.09a2.21,\n        2.21,0,0,0-1.48.26,1.09,1.09,0,0,1-.39.19,1.22,1.22,0,0,0-1,1.16,\n        3.48,3.48,0,0,0,.19,1.36.24.24,0,0,1,0,.22A.74.74,0,0,0,6.82,9.15Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M9.16,1.85a.2.2,0,0,0-.33,0C7.77,3.11,6.08,3.75,3.38,4.06a.28.28,\n        0,0,0-.26.29l.22,5.87a6.76,6.76,0,0,0,1.25,3.27A6.41,6.41,0,0,0,6.09,\n        15,7.69,7.69,0,0,0,9,16.24H9A7.69,7.69,0,0,0,11.91,15a6.55,6.55,0,0,\n        0,1.5-1.48,6.8,6.8,0,0,0,1.25-3.28l.22-5.87a.28.28,0,0,0-.26-.29C11.92,\n        3.75,10.23,3.1,9.16,1.85Zm4.63,8.27a6.2,6.2,0,0,1-1,2.74,2.38,2.38,0,0,\n        0-.89-.52,7,7,0,0,1-1.29-.55A4.4,4.4,0,0,1,9,12.18a3.63,3.63,0,0,\n        1-1.58-.4,3.92,3.92,0,0,1-.65.32L6,12.34a2.4,2.4,0,0,0-.83.52,6.25,\n        6.25,0,0,1-1-2.74L4,4.79a8.65,8.65,0,0,0,5-2,8.65,8.65,0,0,0,5,2Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }